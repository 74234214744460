import React from "react"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../ImageMeta"
import NuvoImage from "../NuvoImage"
import TheaterVideo from "../Theater/TheaterVideo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"

const TestimonialPreview = ({
  image,
  youtube,
  heading,
  subheading,
  reviewerName,
  city,
  procedure,
  withHoverCard,
  hoverLeft,
  language
}) => {
  let attrs = {}
  if (withHoverCard) {
    attrs.aspectRatio = "16:9"
    attrs.crop = "thumb"
    attrs.gravity = "face"
  }

  return (
    <TheaterVideo
      videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
      overVideo
      asWrapper
      language={language}
      wrapperClassName="tp__wrapper"
      childrenWrapperClassName="tp__theater"
      buttonClass="contained"
    >
      <div className={`tp ${heading ? "featured" : ""}`}>
        <NuvoImage
          useAR={false}
          useSkeleton
          className="tp__img block"
          {...attrs}
          cloudname="nuvolum"
          publicId={image}
        />

        {!withHoverCard ? (
          <div className="tp__card watch-cover">
            <FontAwesomeIcon icon={faPlayCircle} />
            <div>
              <MarkdownViewer
                className="mt-0 mb-0"
                as="h5"
                markdown={heading}
              />
              <MarkdownViewer
                className="mt-0 mb-0"
                as="p"
                markdown={subheading}
              />
            </div>
          </div>
        ) : (
          <div className="tp__card icon-only">
            <FontAwesomeIcon icon={faPlayCircle} />
          </div>
        )}

        {withHoverCard && (
          <div className={`tp__hover-card ${hoverLeft ? "left" : "right"}`}>
            <h5>{reviewerName}</h5>
            <MarkdownViewer as="p" markdown={city} />
            <MarkdownViewer as="p" markdown={procedure} />
          </div>
        )}
      </div>
    </TheaterVideo>
  )
}

export default TestimonialPreview
